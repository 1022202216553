import { Country, Environment } from "@/enums/Config";
import { IConfig } from "@/models/Config";
import { IEnvVars } from "@/models/Env";

export default {
  country: jest.fn((): Country => Country.ES),
  config: jest.fn(
    (): IConfig => ({
      WEBAPP_URL: "http://localhost:3000",
      MYHOUSFY_URL: "http://localhost:30001",
      MYREALESTATE_URL: "http://localhost:30003",
      MYRENTALS_URL: "http://localhost:30002",
      MYMORTGAGES_URL: "http://localhost:30004",
    })
  ),
  env: jest.fn(
    (): IEnvVars => ({
      ENVIRONMENT: Environment.LOCAL,
      HOUSFY_API_URL: "http://api.housfy.test",
      TRACK_EVENTS: "false",
      STITCH_URL: "https://url.test.stitch",
      GOOGLE_MAPS_API_KEY_SECRET: "key",
    })
  ),
};
